<script>
    export default {
        name:"UnionpayTEMPLATE1",
        components: {
            'mt-header': app.Mint.Header,
            'mt-button': app.Mint.Button,
            'mt-actionsheet': app.Mint.Actionsheet,
        },
        props:[
            'sourceType',
            'pay_price',
            'sourceType1',
            'pay_price',
            'pname',
            'payList',
            'remark',
            'options',
            'optionsTrue',
            'options1',
            'options1True',

        ],
        mounted() {
            this.myrecharge();
            this.CustomScroll(this.$refs.YHKWid.id);
        },
        inject:[
            'myrecharge',
            'YHKselect',
            'commit',
        ],
    }
</script>
<template>
    <DIV id='recharge' ref="recharge">
        <mt-header :title="getWord('pay_app3')" style="height:0.88rem;font-size: 0.35rem;">
            <router-link to="/money" slot="left">
                <mt-button icon="back"></mt-button>
            </router-link>
        </mt-header>
        <div class="text_">
            <P style="color:#4796fc;font-size:0.28rem;line-height:0.5rem;">{{ getWord('transfer_rules') }}</P>
            <p style="font-size:0.28rem;margin-top:0.1rem;"><span style="border-radius:15px;background:#4796fc;color:#FFF;padding:0 0.1rem;margin-right:0.1rem;">1</span>{{ getWord('confirm_and_transfer') }}</p>
        </div>
        <div class="YHK">
            <div class="YHKoverflow-x" id="YHKWid" ref="YHKWid">
                <label class="YHKlab" v-for="(it,i) in payList" :key="i" :id="'label'+i">
                    <img class="YHKback" :src="it.background_pic">
                    <img class="YHKLogo" :src="it.logo"><p class="YHKaccName">{{it.bank_name}}</p><p class="YHKpopname">{{it.payee}}</p>
                    <p class="YHKaccNum">{{it.account_num}}</p><p class="YHKFZbutton" :data-clipboard-text="it.account_num" @click="copyText()">{{ getWord('copy') }}</p>
                    <p class="YHKsel">{{ getWord('confirm_and_transfer2') }}</p><input @blur="_blured($event)" class="YHKinp" type="radio" name="YHK" @click="YHKselect(it)" />
                </label>
            </div>
        </div>
        <div class="text_">
            <p style="font-size:0.28rem;margin-top:0.1rem;"><span style="border-radius:15px;background:#4796fc;color:#FFF;padding:0 0.1rem;margin-right:0.1rem;">2</span>{{ getWord('fill_offline_transfer_info') }}</p>
        </div>
        <div class="block">
            <div class="huikuan" @click="optionsTrue=true">
                <span class="wenzi">{{ getWord('bank_name3') }}</span>
                <img class="main_header_select_img" src="@@/assets/images2/user/sj.png">
                {{sourceType['name']}}
            </div>
        </div>
        <div class="huikuan" style="border-bottom:none;">
            <span class="wenzi">{{ getWord('username3') }}</span>
            <input @blur="_blured($event)" type="text" v-model="pname" :placeholder="getWord('name_placeholder')">
        </div>
        <div class="huikuan">
            <span class="wenzi">{{ getWord(['transfer2', 'amount_of_betting']) }}</span>
            <input @blur="_blured($event)" type="text" oninput = "value=value.replace(/[^\d]/g,'')" v-model="pay_price" :placeholder="getWord(['fill', 'transfer2', 'amount_of_betting'])">
        </div>
        <div class="huikuan" @click="options1True=true" style="border-top:none;">
            <span class="wenzi">{{ getWord('transfer_address') }}</span>
            <img class="main_header_select_img" src="@@/assets/images2/user/sj.png">
            {{sourceType1['name']}}
        </div>
        <div class="submit_btn">
            <mt-button size="large" @click="commit()" style="color:#FFF;">{{ getWord(['submit', 'deposit', 'apply_for']) }}</mt-button>
        </div>
        <div class="text_ts">
            <P>{{ getWord('tips') }}:</P>
            <div v-html="remark"></div>
        </div>
        <mt-actionsheet :actions="options" v-model="optionsTrue" class="footer"></mt-actionsheet>
        <mt-actionsheet :actions="options1" v-model="options1True"></mt-actionsheet>
    </DIV>
</template>
<style lang="scss" scoped>
    #recharge {
        padding-bottom: .5rem;
        overflow: auto;
    }
    .footer{
        float: left;
        height:8rem;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .main_header_select_img{
        float: right;
        width: 0.15rem;
        height: 0.15rem;
        margin: 0.3rem 0.2rem 0 0;
    }
    .mint-button--default {

        background-color: #4796fc;
    }
    .huikuan {
        border: 1px solid #ccc;
        width: 6.09rem;
        margin-left: 0.68rem;
        line-height: 35px;
        color:#888;
        font-size:0.26rem;
        &>span{
            width:26%;
        }
        &>input{
            width:74%;
            margin-left:0;
            font-size:0.26rem;
        }
    }
    .wenzi {
        color: #ccc;
        float: left;
        text-indent: 0.2rem;
        color:#000;
        font-size:0.28rem;
    }
    .huadong {
        height: 3.4rem;
        width: 7.5rem;
        float: left;
    }
    .text_ {
        margin-left: 0.68rem;
        margin: 5% 0 5% 0.68rem;
        font-size: 0.3rem;
    }
    select {
        margin-left: 20%;
        width: 3.67rem;
        height: 0.7rem;
        border: none;
    }

    input {
        border: none;
        margin-left: 20%;
        width: 3.09rem;
        height: 0.7rem;
    }

    .YHK{
        width: auto;
        height:3.2rem;
        margin:0 0.3rem 0.3rem 0.3rem;
        overflow-y: hidden;
        overflow-x: auto;
        position: relative;
            .YHKoverflow-x{
                width:auto;
                white-space: nowrap;
                .YHKlab{
                    width: 6.1rem;
                    height: 3.2rem;
                    position:relative;
                    margin-right:10px;
                    .YHKback {
                        width: 6.1rem;
                        height: 3.2rem;
                        background-color: brown;
                        border-radius: 0.25rem;
                        position:relative;
                    }
                    .YHKLogo {
                        width: 0.7rem;
                        height: 0.7rem;
                        background-image: url('/static/assets/images2/money/chinaYinHangLogo.png');
                        background-size: 100%;
                        position:absolute;
                        top:-2.6rem;
                        left:0.3rem;
                    }
                    .YHKpopname{
                        position:absolute;
                        top:-2.1rem;
                        left:1.2rem;
                        color:#FFF;
                        font-size:0.3rem;
                    }
                    .YHKaccName{
                        position:absolute;
                        top:-2.6rem;
                        left:1.2rem;
                        color:#FFF;
                        font-size:0.35rem;
                    }
                    .YHKaccNum {
                        width:4rem;
                        height: 0.5rem;
                        color:#FFF;
                        font-size:0.38rem;
                        position:absolute;
                        top:-1.6rem;
                        left:0.3rem;
                    }
                    .YHKsel{
                        width:5rem;
                        height:0.5rem;
                        color:#FFf;
                        font-size:0.26rem;
                        position:absolute;
                        top:-0.6rem;
                        left:0.3rem;
                    }
                    .YHKinp{
                        width:0.3rem;
                        height:0.3rem;
                        position:absolute;
                        top:-0.5rem;
                        left:4rem;
                    }
                    .YHKFZbutton{
                        width:1rem;
                        height:0.5rem;
                        line-height:0.5rem;
                        text-align:center;
                        background:#1671fb;
                        border-radius: 0.1rem;
                        color:#FFF;
                        position:absolute;
                        top:-1.6rem;
                        left:4.9rem;
                    }
                }
            }
                    
    }

    .submit_btn {
        margin-top: 5%;
        width: 6.09rem;
        height: 0.88rem;
        margin-left: 0.7rem;
    }

    .text_ts {
        margin-left: 0.7rem;
        margin-top:0.15rem;
        line-height:0.36rem;
        margin-right: 1.03rem;
        color: #aaaaaa;
        font-size: 0.24rem;
    }
    .block {
        float: left;
        width: 100%;
    }
</style>